<template>
    <div>
        <zw-input-group v-model="inputVal.percent"
                        name="percent"
                        :label-prefix="labelPrefix"
                        validate="required"
                        type="number"
        ></zw-input-group>

        <b-form-checkbox v-model="inputVal.send">{{ $t('settings.workflow.label.send_email') }}</b-form-checkbox>

        <b-row v-if="inputVal.send">
            <b-col cols="12">
                <zw-select-group v-model="inputVal.template"
                                 :options="getEmailTemplates()"
                                 name="template"
                                 :label-prefix="labelPrefix"
                                 text-field="name"
                                 value-field="id"
                                 validate="required"
                ></zw-select-group>
            </b-col>
        </b-row>
    </div>

</template>

<script>

import {mapGetters} from "vuex";

export default {
    name: 'GenerateInvoiceOptions',
    props: {
        'value': [String, Number, Object],
    },
    data() {
        return {
            loading: true,
            labelPrefix: 'settings.workflow.label.',
        }
    },
    methods: {
        ...mapGetters('CommonData', ['getEmailTemplates']),
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
    },
}
</script>